import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { disconnect } from "./redux/blockchain/blockchainActions";

const WalletInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 4px;
`;

const WalletAddress = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: var(--secondary);
`;

const DisconnectButton = styled.button`
  background-color: transparent;
  border: 1px solid var(--secondary);
  color: var(--secondary);
  padding: 2px 8px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: var(--secondary);
    color: var(--primary);
  }
`;
const truncate = (str, num) => {
    if (!str || str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  export const disconnectWallet = () => {
    const dispatch = useDispatch();
    dispatch(disconnect());
  };
  
  const WalletInfo = () => {
    const dispatch = useDispatch();
    const blockchain = useSelector(state => state.blockchain);
  
    const handleDisconnect = () => {
      dispatch(disconnectWallet());
    };
  
    if (blockchain.account === '') {
      return null;
    }
  
    return (
      <WalletInfoContainer>
        <WalletAddress>{truncate(blockchain.account, 15)}</WalletAddress>
        <DisconnectButton onClick={handleDisconnect}>Disconnect</DisconnectButton>
      </WalletInfoContainer>
    );
  };
  
  export default WalletInfo;
  
  
  
  
  
  
  