// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = payload => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = payload => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

const setTotalSupply = payload => {
  return {
    type: "SET_TOTAL_SUPPLY",
    payload: payload,
  };
};

export const fetchTotalSupply = () => {
  return async dispatch => {
    try {
      const totalSupply = await dataBeforeConnect();
      dispatch(setTotalSupply(totalSupply));
    } catch (err) {
      console.error(err);
    }
  };
};


export const fetchData = () => {
  console.log("asdj f;lajs ;ljdslsl lsa ds");
  return async dispatch => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
