import { useState, useEffect } from 'react';
import Web3 from 'web3';
import abi from './contract_abi.json';

const contractAddress = '0x22f367Aab630db323199e2B1A14dfd93373d2659';
const providerUrl = 'https://mainnet.infura.io/v3/e97f313b67e44194bb97c0184bc241f5';

const web3 = new Web3(new Web3.providers.HttpProvider(providerUrl));
const contract = new web3.eth.Contract(abi, contractAddress);

const useMintedNFTCount = () => {
  const [mintedNFTCount, setMintedNFTCount] = useState(null);

  useEffect(() => {
    const getMintedNFTCount = async () => {
      try {
        const totalSupply = await contract.methods.totalSupply().call();
        setMintedNFTCount(totalSupply);
      } catch (error) {
        console.error('Error fetching minted NFT count:', error);
      }
    };
    getMintedNFTCount();
  }, []);

  return mintedNFTCount;
};

export default useMintedNFTCount;
