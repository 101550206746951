import styled from 'styled-components'

// Used for wrapping a page component
export const Screen = styled.div`
  background-image: url('/config/images/sky-bg.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    background-size: cover;
  }
`

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : 'column')};
  justify-content: ${({ jc }) => (jc ? jc : 'flex-start')};
  align-items: ${({ ai }) => (ai ? ai : 'flex-start')};
  background-color: ${({ test }) => (test ? 'pink' : 'none')};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  background-size: cover;
  background-position: center;
`

export const TextTitle = styled.p`
  color: var(--primary-text);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
  font-family: soin sans;
  text-shadow: ${({ shadow }) => (shadow ? shadow : '')};
  @media (max-width: 767px) {
    font-size: 18px;
  }
`

export const TextSubTitle = styled.p`
  color: var(--primary-text);
  font-size: 18px;
  line-height: 1.6;
`

export const TextDescription = styled.p`
  color: var(--accent-text);
  font-size: 16px;
  line-height: 1.6;
  font-family: soin sans;
`

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`

export const StyledButton = styled.button`
  z-index: 10000;
  border-radius: 50px;
  border: 2px solid var(--accent-text);
  background-color: transparent;
  padding: 15px 10px;
  font-weight: 300;
  color: var(--accent-text);
  width: 120px;
  cursor: pointer;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  font-family: soin sans;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 1px 2px 9px 3px rgba(0, 0, 0, 0.18);
`;

export const StyledRoundButton = styled.button`
  z-index: 10000;
  padding: 10px;
  border-radius: 100%;
  border: 2px solid var(--accent-text);
  background-color: transparent;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--accent-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledDisconnectButton = styled(StyledButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
  font-family: "soin sans";
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 300px;
  @media (min-width: 767px) {
    width: 300px;
  }
  @media (max-width: 767px) {
    width: ${({ width }) => width || "300px"};
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLogoFooter = styled.img`
  width: 100px;
  @media (min-width: 767px) {
    width: 150px;
  }
  @media (max-width: 767px) {
    width: ${({ width }) => width || "150px"};
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledConnect = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  @media (max-width: 767px) {
    width: ${({ width }) => width || "300px"};
  }
  transition: width 0.5s;
  transition: height 0.5s;
  transform-style: preserve-3d;
  // transform: rotateX(70deg);
  transition: all 0.8s;
  &:hover {
    transform: rotateX(70deg);
  }
  &:after {
    transform: rotateX(-90deg);
    transform-origin: bottom;
  }
`;

export const StyledImg = styled.img`
  background-color: var(--accent);
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;