// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import detectEthereumProvider from "@metamask/detect-provider";
// log
import { fetchData } from "../data/dataActions";

let web3Modal = "";
let provider = "";
const init = () => {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "f64084d938db45b4b54b604b6593bf71",
      },
    },
  };
  web3Modal = new Web3Modal({
    cacheProvider: false,
    providerOptions,
  });
};

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = payload => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = payload => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = payload => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  init();
  return async dispatch => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();

    try {
      provider = await web3Modal.connect();
      console.log(provider);
      Web3EthContract.setProvider(provider);
      let web3 = new Web3(provider);
      try {
        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.getChainId();

        if (networkId == CONFIG.NETWORK.ID) {
          const SmartContractObj = new Web3EthContract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
            })
          );
        } else {
          dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
        }
      } catch (err) {
        dispatch(connectFailed("Something went wrong."));
      }
    } catch (e) {
      dispatch(connectFailed(e.message));
      return;
    }
  };
};

export const updateAccount = account => {
  return async dispatch => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};

export const dataBeforeConnect = () => {
  return new Promise(async (resolve, reject) => {
    try {
      provider = await detectEthereumProvider();

      Web3EthContract.setProvider(provider);
      let web3 = new Web3(provider);

      const abiResponse = await fetch("/config/abi.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const abi = await abiResponse.json();
      const configResponse = await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const CONFIG = await configResponse.json();

      try {
        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.getChainId();

        const SmartContractObj = new Web3EthContract(
          abi,
          CONFIG.CONTRACT_ADDRESS
        );

        return resolve(await SmartContractObj.methods.totalSupply().call());
      } catch (err) {
        reject(err);
      }
    } catch (err) {
      reject(err);
    }
  });
};


export const disconnect = () => ({
  type: 'DISCONNECT',
});