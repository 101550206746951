import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Helmet } from 'react-helmet';

import {
  connect,
  dataBeforeConnect,
} from "./redux/blockchain/blockchainActions";
import { fetchData, fetchTotalSupply } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import { StyledLink, StyledButton, StyledRoundButton, StyledDisconnectButton, ResponsiveWrapper, StyledLogo, StyledLogoFooter, StyledConnect, StyledImg } from './styles/globalStyles';
import useMintedNFTCount from './useMintedNFTCount';
import WalletInfo, { disconnectWallet } from './WalletInfo';
import { disconnect } from './redux/blockchain/blockchainActions';

const CONTRACT_ADDRESS = '0x22f367Aab630db323199e2B1A14dfd93373d2659';

function truncate(input, len) {
  return input.length > len ? `${input.substring(0, len)}...` : input;
}

function App() {
  const [totalSupply, setTotalSupply] = useState(0);
  const dispatch = useDispatch();
  const blockchain = useSelector(state => state.blockchain);
  const data = useSelector(state => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Mint up to 20 NFTs`);
  const [mintAmount, setMintAmount] = useState(1);
  const [showDisconnect, setShowDisconnect] = useState(false);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const [beforeConnectsupply, setSupply] = useState(0);

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Manifesting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", err => {
        console.log(err);
        setFeedback("Sorry, something went wrong. Please try again later.");
        setClaimingNft(false);
      })
      .then(receipt => {
        console.log(receipt);
        setFeedback(`Mint successful! View it on OpenSea`);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 20) {
      newMintAmount = 20;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const mintedNFTCount = useMintedNFTCount();

  useEffect(() => {
    async function fetchData() {
      await getConfig();

      let val = await dataBeforeConnect();

      if (val) {
        setSupply(val);
      }
    }

    fetchData();
  }, []);


  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-H5VYRQTZMD"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-H5VYRQTZMD');
      `,
          }}
        />
      </Helmet>
      <WalletInfo /> {/* Add the WalletInfo component here */}
      <s.Screen>
        <s.Container
          flex={1}
          ai={"center"}
          style={{
            padding: 50,
          }}
        >
          <a href={CONFIG.MARKETPLACE_LINK}>
            <StyledLogo
              alt={"logo"}
              src={"/config/images/2222angelclub-logo.png"}
            />
          </a>
          {/* <s.SpacerSmall /> */}
          <ResponsiveWrapper flex={1} test>
            {/* <s.SpacerLarge /> */}
            <s.Container
              flex={2}
              jc={"center"}
              ai={"center"}
              style={{
                padding: 12,
                borderRadius: 0,
              }}
            >
              <s.TextTitle
                style={{
                  textAlign: "center",
                  fontSize: 90,
                  fontWeight: 500,
                  color: "var(--accent-text)",
                  fontFamily: "blosta",
                }}
              >
                {data.totalSupply ? data.totalSupply : mintedNFTCount}/
                {CONFIG.MAX_SUPPLY}
              </s.TextTitle>
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--accent-text)",
                  zIndex: 1,
                }}
              >
                <StyledLink
                  target={"_blank"}
                  href={CONFIG.SCAN_LINK}
                  style={{ fontSize: 30 }}
                >
                  {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                </StyledLink>
              </s.TextDescription>
              <span
                style={{
                  textAlign: "center",
                  zIndex: 1,
                }}
              >
                <StyledButton
                  onClick={e => {
                    window.open("/config/roadmap.pdf", "_blank");
                  }}
                  style={{
                    margin: "5px",
                  }}
                >
                  Roadmap
                </StyledButton>
                <StyledButton
                  style={{
                    margin: "5px",
                  }}
                  onClick={e => {
                    window.open(CONFIG.MARKETPLACE_LINK, "_blank");
                  }}
                >
                  {CONFIG.MARKETPLACE}
                </StyledButton>
                {
  blockchain.account && (
    <StyledDisconnectButton
      onClick={() => {
        disconnectWallet();
        setShowDisconnect(false);
      }}
    >
      Disconnect
    </StyledDisconnectButton>
  )
}

              </span>
              <s.SpacerSmall />
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    The sale has ended.
                  </s.TextTitle>
                  <s.TextDescription
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    You can still find {CONFIG.NFT_NAME} on
                  </s.TextDescription>
                  <s.SpacerSmall />
                  <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                    {CONFIG.MARKETPLACE}
                  </StyledLink>
                </>
              ) : (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                    {CONFIG.NETWORK.SYMBOL}
                  </s.TextTitle>
                  <s.SpacerXSmall />
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                      marginTop: -20,
                    }}
                  >
                    excluding gas fees
                  </s.TextTitle>
                  <s.SpacerSmall />
                  {blockchain.account === "" || blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.SpacerSmall />
                      <StyledButton
                        onClick={e => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                          setShowDisconnect(true);
                        }}
                      >
                        CONNECT
                      </StyledButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledRoundButton
                          style={{ lineHeight: 0.4 }}
                          disabled={claimingNft ? 1 : 0}
                          onClick={e => {
                            e.preventDefault();
                            decrementMintAmount();
                          }}
                        >
                          -
                        </StyledRoundButton>
                        <s.SpacerMedium />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {mintAmount}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        <StyledRoundButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={e => {
                            e.preventDefault();
                            incrementMintAmount();
                          }}
                        >
                          +
                        </StyledRoundButton>
                      </s.Container>
                      <s.SpacerSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={e => {
                            e.preventDefault();
                            claimNFTs();
                            getData();
                          }}
                        >
                          {claimingNft ? "BUSY" : "MINT"}
                        </StyledButton>
                      </s.Container>
                    </>
                  )}
                </>
              )}
              {/* <s.SpacerMedium /> */}
            </s.Container>
            {/* <s.SpacerLarge /> */}
          </ResponsiveWrapper>
          {/* <s.SpacerMedium /> */}
          <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
            <s.TextDescription
              style={{
                textAlign: "center",
              }}
            >
              {/* Please make sure you are connected to the right network (
              {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
              Once you make the purchase, you cannot undo this action. */}
              powered by
            </s.TextDescription>
            <s.SpacerSmall />
            <StyledLogoFooter
              alt={"logo"}
              src={"/config/images/2222angelclcub-MMCCXXII.png"}
            />
          </s.Container>
        </s.Container>
      </s.Screen>
    </>
  );
}

export default App;
